export const initialOpen = {
    subjectLoad: false,
    lockConfirmation: false,
    confirmation: false,
    scheduler: false,
    viewStudents: false,
}
export const initialRows = {
    viewStudents: [],
    subjectLoad: [],
    emails: [],
}

export const initialLoading = {
    viewStudents: true,
    subjectLoad: true,
    emails: true,
}
